import type { ListColumnDefs } from '~/components/ui/List/definitions'

export const HOMEPAGE_DROPDOWN_OPTIONS = [
  { name: 'Chemical Lithium', value: 'chemical' },
  { name: 'Mined Lithium', value: 'mined' },
]

export const TOP_PRODUCERS_LIMIT = 5

const toPercentage = (value) => `${+value.toFixed(2)}%`

export const TOP_PRODUCERS_COLUMN_DEFS: ListColumnDefs[] = [
  { field: 'name', class: 'w-auto text-left flex-grow text-sm' },
  {
    field: 'marketShare',
    formatter: ({ marketShare }) => toPercentage(marketShare),
    class: 'text-sm',
  },
  { field: 'production', class: 'w-24 text-right text-sm' },
]
